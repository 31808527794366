const className = {
  rowBackgroundColor: "style-rowBackground",
  rowBackgroundColor1: "style-rowBackground-1",
  rowBackgroundColor2: "style-rowBackground-2"
};

const varName = {
  dataTableBackgroundColor: "--dataTable-background-color"
};

/**
 * wrapped Text style
 * which allows the text to wrap and take up multiple lines.
 * @type {string}
 */
const wrappedText = "white-space: normal;";

export { className, varName, wrappedText };
